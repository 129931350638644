$text-color: #c1d6da;
$teal-shadow: #18afb4;
$select-color: rgb(105, 82, 240);
//$violet-border: #5448ff;
$violet-border: #4E58EF;
$violet: #4E58EF;
$violet-shadow: #7c2bff;
$green: #0d8b66;
$green-shadow: #008b17;
$bg-color-1: #14151a;
$bg-color-2: #1a181d;
$bg-color-1-m: #0b0b0e;
$bg-color-2-m: #17151a;

@mixin max-half-width {
  max-width: calc((140rem - 8rem) / 2);
}

@mixin half-width {
  width: calc((100% - 16rem) / 2);
}

@mixin max-third-width {
  max-width: calc((140rem - 16rem) / 3);
}

@mixin third-width {
  width: calc((100% - 24rem) / 3);
}

@mixin max-two-thirds {
  max-width: calc((140rem - 8rem) / 3 * 2);
}

@mixin two-thirds {
  width: calc((100% - 12rem) / 3 * 2);
}

@mixin light-text {
  color: $text-color;
  text-shadow: 0px 0px 6px $teal-shadow;
}

@mixin violet-text {
  color: $violet;
  text-shadow: 0px 0px 6px $violet-shadow;
}

@mixin green-text {
  color: $green;
  text-shadow: 0px 0px 6px $green-shadow;
}

@mixin border {
  border: 2px solid $text-color;
  -webkit-box-shadow: 0px 0px 6px $teal-shadow;
          box-shadow: 0px 0px 6px $teal-shadow;
  padding: 4rem;
}



@-webkit-keyframes shutter-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-1500px) translateX(-50%) rotateY(70deg);
            transform: translateZ(-1500px) translateX(-50%) rotateY(70deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(0) translateX(0);
            transform: rotateY(0deg) translateZ(0) translateX(0);
  }
}

@keyframes shutter-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-1500px) translateX(-50%) rotateY(70deg);
            transform: translateZ(-1500px) translateX(-50%) rotateY(70deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateY(0deg) translateZ(0) translateX(0);
            transform: rotateY(0deg) translateZ(0) translateX(0);
  }
}



@mixin pulsate-fwd-animation {
  -webkit-animation: pulsate-fwd 2s linear infinite alternate both;
          animation: pulsate-fwd 2s linear infinite alternate both;
}

@-webkit-keyframes pulsate-fwd {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulsate-fwd {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin pulsate-fwd-bck-animation {
  -webkit-animation: pulsate-pulsate-fwd-bck 1s linear;
          animation: pulsate-pulsate-fwd-bck 1s linear;
}

@-webkit-keyframes pulsate-fwd-bck {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pulsate-fwd-bck {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes blink {
  0%,
  66%{
    opacity: 0;
  }
  33%,
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0%,
  66%{
    opacity: 0;
  }
  33%,
  100% {
    opacity: 1;
  }
}

@mixin scale-in-center {
	-webkit-animation: scale-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}



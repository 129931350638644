@import '../styles/variables.scss';

.clients {
  padding: 8rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  //flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 -4rem;
  -webkit-perspective: 1500px;
          perspective: 1500px;

  .col-1 {
    @include third-width;
    margin: 8rem 4rem;
    -webkit-perspective: 1500px;
            perspective: 1500px;
  }

  .col-2 {
    @include half-width;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 8rem 4rem;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

    .content {
      -ms-flex-item-align: end;
          align-self: flex-end;
    }
  }

  h6 {
    text-align: center;
    margin: 4.8rem 0 2.4rem 0;
  }
  .items {
    margin: 0 -2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    div {
      margin: 2rem;
      width: 25%;
    }

    a {
      padding: 0;
    }
  }

  .cta {
    margin: 2rem 0;
    .link-container {
      a {
        padding: 4rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        .cta_text {
          font-family: 'Source Code Pro', monospace;
          font-size: 2rem;
          font-weight: 600;
          line-height: 1.6;
          margin: 2rem;
        }
        .cta_title {
          text-transform: unset;
          font-size: 4rem;
          font-weight: 700;
          letter-spacing: 1px;
          line-height: 1.2;
          margin: 2rem 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .clients {
    .col-1 {
      @include half-width;
    }
    .items {
      div {
        margin: 2rem 1rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .clients {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    .col-1 {
      @include two-thirds;
      margin: 0 4rem;
    }
    .col-2 {
      width: calc(100% - 8rem);
    }
  }
}

@media screen and (max-width: 768px) {
  .clients {
    padding: 4rem 0 0 0;
    h6 {
      max-width: 48rem;
      margin: 0 auto;
    }
    .col-1 {
      width: calc(100% - 8rem);
    }

    .cta {
      .link-container {
        a {
          padding: 1rem;
          .cta_title {
            font-size: 3.2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .clients {
    .col-2 {
      .items {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        div {
          margin-bottom: 0;
          img {
            margin: 0 auto;
          }
        }
      }
    }
  }
}

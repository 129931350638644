@import '../styles/variables.scss';

.hero {
  height: calc(100vh - 35rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 4rem 0;
  padding: 1rem;

  .content {
    max-width: 700px;
    @include two-thirds;
    -webkit-perspective: 1500px;
    perspective: 1500px;

    h2,
    object,
    img {
      -webkit-animation: shutter-in 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: shutter-in 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    img,
    object {
      display: none;
    }
    .outer-link-container {
      display: inline-block;
      -webkit-transform-origin: left;
      transform-origin: left;
      margin: 3rem 0;
      -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      -webkit-animation: shutter-in 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
      animation: shutter-in 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
      &:hover {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
      }
      .link-container {
        -webkit-animation: none;
        animation: none;
      }
    }
  }
}

@media screen and (max-width: 1500px) and (min-width: 1300px) {
  .hero {
    padding: 0 0 0 12rem;
  }
}

@media screen and (max-width: 1200px) {
  .hero {
    .content {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .hero {
    height: calc(100vh - 28rem);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .content {
      width: 100%;
      max-width: 400px;
      text-align: center;
      padding-top: 20%;
      padding-left: 0;

      img,
      object {
        display: block;
        margin: 1rem auto;
        width: 150px;
      }

      h2 {
        margin: 3rem auto 2rem auto;
        font-size: 1.6rem;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 1.5;
        max-width: 26rem;
      }

      .outer-link-container {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .hero {
    .content {
      max-width: 260px;
      padding-left: 0;
    }
  }
}

@media screen and (max-height: 600px) {
  .hero {
    .content {
      h2 {
        margin: 1rem 0 0 0;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 1.3;
      }
    }
  }
}

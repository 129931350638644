@import '../styles/variables.scss';

.career {
  padding: 12rem 0 8rem 0;

  h5 {
    @include max-half-width;
  }

  .content {
    margin: 8rem 0;
    .cols {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 -4rem;
      -webkit-perspective: 1500px;
      perspective: 1500px;

      .col-1 {
        @include half-width;
        margin: 0 4rem;
        -webkit-animation-delay: 0.1s;
                animation-delay: 0.1s;

        p:nth-child(1) {
          margin-top: 8rem;
          margin-bottom: 2rem;
        }
        p:nth-child(2) {
          margin-bottom: 4rem;
        }
        div {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
        }
      }
      .col-2 {
        @include third-width;
        margin: 4rem;
        -webkit-animation-delay: 0.2s;
                animation-delay: 0.2s;
        p,
        h6 {
          //font-family: 'Poppins', sans-serif;
          margin: 4rem 0 2rem 0;
          text-align: left;
          //letter-spacing: 2px;
          //font-size: 3.2rem;
          line-height: 1.5;
        }
        ul {
          line-height: 2;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .career {
    padding: 12rem 0 0 0;
    .content {
      .cols {
        .col-1 {
          width: 100%;
          margin: 4rem;
        }
        .col-2 {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .career {
    padding: 4rem 0 0 0;
    .content {
      margin: 2rem 0;
      .cols {
        .col-1 {
          margin: 0 4rem;
          p:nth-child(1) {
            margin-top: 2rem;
          }
          p:nth-child(2) {
           margin-bottom: 2rem;
          }
        }
        .col-2 {
          p {
            margin-top: 2rem;

            font-size: 2.4rem;
            line-height: 1.4;
          }
          ul {
            font-weight: 600;
          }
        }
      }
    }
  }
}

@import '../styles/variables.scss';

.not-found {
  height: calc(100vh - 35rem);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    .col {
      margin: 2rem 8rem;
    }
  }

  p {
    //@include green-text;
    margin: 0 0 8rem 0;
  }

  h5 {
    font-size: 10rem;
    font-weight: 200;
    margin: 0 0 0 0;
    line-height: 8rem;
    letter-spacing: 4px;
    //@include green-text;
  }

  h6 {
   // font-family: 'Source Code Pro', monospace;
    @include light-text;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0.8rem 0 1.6rem 0;
    font-size: 3.2rem;
    line-height: 1.2;
    letter-spacing: 1px;
    //@include green-text;
  }
  .link-container {
    width: 100%;
  }
}
.radar__frame {
  padding: 0.8rem;
  border-radius: 50%;
  border: 4px solid $text-color;
  box-shadow: 0px 0px 8px $teal-shadow, inset 0px 0px 2px $teal-shadow;
}
.radar__dial {
  position: relative;
  margin: 0;
  padding: 0;
  width: 48rem;
  height: 48rem;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid $green;
  box-shadow: 0px 0px 8px $green-shadow, inset 0px 0px 8px $green-shadow;
}

/* lines */
.radar li:nth-child(1),
.radar li:nth-child(2),
.radar li:nth-child(3),
.radar li:nth-child(4) {
  position: absolute;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: $green;
  box-shadow: 0px 1px 4px $green-shadow, 0px -1px 4px $green-shadow;
  //border-radius: 50%;
  opacity: 0.4;
}
.radar li:nth-child(2) {
  transform: rotate(90deg);
}
.radar li:nth-child(3) {
  transform: rotate(45deg);
}
.radar li:nth-child(4) {
  transform: rotate(-45deg);
}

/* circles */
.radar li:nth-child(5),
.radar li:nth-child(6),
.radar li:nth-child(7) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid $green;
  box-shadow: 0px 0px 4px $green-shadow, inset 0px 0px 4px $green-shadow;
  background: transparent;
  border-radius: 50%;
  opacity: 0.4;
}

.radar li:nth-child(5) {
  width: 180px;
  height: 180px;
}

.radar li:nth-child(7) {
  width: 320px;
  height: 320px;
}

.radar li:nth-child(8) {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  background: linear-gradient(45deg, #008b35 0%, transparent 50%);
  transform-origin: top left;
  animation: rotation 8s linear infinite;
  //border-left: 2px solid $green;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-height: 800px) {
  .not-found {
    height: calc(100vh - 30rem);
    h5 {
      margin: 2rem 0 0 0;
    }
  }
  .radar__dial {
    width: 30rem;
    height: 30rem;
  }
  .radar li:nth-child(5) {
    width: 10rem;
    height: 10rem;
  }
  .radar li:nth-child(7) {
    width: 20rem;
    height: 20rem;
  }
}

@media screen and (max-width: 768px) {
  .not-found {
    height: calc(100vh - 8rem);
    h5 {
      margin: 2rem 0 0 0;
    }
  }
  .radar__dial {
    width: 30rem;
    height: 30rem;
  }
  .radar li:nth-child(5) {
    width: 10rem;
    height: 10rem;
  }
  .radar li:nth-child(7) {
    width: 20rem;
    height: 20rem;
  }
}

@media screen and (max-width: 414px) {
  .not-found {
    height: 100%;
    .content {
      .col {
        margin: 0 2rem;
      }
      .col:nth-child(2) {
        margin: 2rem 0;
      }
    }
    h5 {
      font-size: 6rem;
      margin: 4rem 0 0 0;
      line-height: 5rem;
      letter-spacing: 0;
    }
    h6 {
      font-size: 2rem;
      margin: 0 0 0.8rem 0;
      letter-spacing: 0;
    }
  }

  .radar__frame {
    border-width: 2px;
    //padding: 0.4rem;
  }
  .radar__dial {
    width: 18rem;
    height: 18rem;
  }
  .radar li:nth-child(5) {
    width: 6rem;
    height: 6rem;
  }
  .radar li:nth-child(7) {
    width: 12rem;
    height: 12rem;
  }
}

/* Global Styles */

@import '../styles/variables';

::selection {
  background: $select-color;
}

html {
  font-size: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.6 !important;
  color: $text-color;
  background-image: linear-gradient(355deg, $bg-color-2 2%, $bg-color-1 30%);
  background-color: $bg-color-2;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-shadow: 0px 0px 6px $teal-shadow;
}

.top-container {
  position: relative;
  height: 100vh;
}
.top-container--small {
  position: relative;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
}

a,
button {
  width: 100%;
  text-align: center;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-size: 2.4rem;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 8px;
  color: $violet;
  text-shadow: 0px 0px 6px $violet-shadow;
  padding: 1.6rem 4rem;
  -webkit-transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &:focus {
    outline: none;
  }
}

.large-a {
  display: inline;
  text-align: left;
  text-transform: unset;
  font-size: 6.4rem;
  font-weight: 200;
  letter-spacing: 2px;
  padding: 1.6rem 0;
  //padding: 3.2rem 6rem;
}

.link-container {
  cursor: pointer;
  display: inline-block;
  margin: 2rem 0;
  border: 2px solid transparent;
  border: 2px solid $violet;
  -webkit-box-shadow: 0px 0px 6px $violet-shadow, inset 0px 0px 6px $violet-shadow;
  box-shadow: 0px 0px 6px $violet-shadow, inset 0px 0px 6px $violet-shadow;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

ul {
  list-style-type: none;
  padding: 0;
}

/* Typography */

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 8rem;
  font-weight: 200;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: -0.2px;
  text-shadow: 0px 0px 12px $teal-shadow;
  //@include text-stroke;
}

h3 {
  font-size: 1.6rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: $violet;
  text-shadow: 0px 0px 6px $violet-shadow;
  margin-bottom: 2.4rem;
}

h4 {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.2;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  @include green-text;
  margin-bottom: 2.4rem;
}

h5 {
  font-size: 6.4rem;
  line-height: 1.2;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-shadow: 0px 0px 6px $teal-shadow;
  margin: 0 0 2.4rem 0;
}

h6 {
  font-family: 'Poppins', sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
  letter-spacing: 2px;
  //text-transform: uppercase;
  @include green-text;
}

.h-green {
  @include green-text;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 8px;
  margin: 0 0 2.4rem 0;
}

.h-medium {
  font-size: 4.8rem;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1.2;
}

.emphasis {
  font-family: 'Poppins', sans-serif;
  @include green-text;
  font-weight: 800;
  letter-spacing: 2px;
  //margin-bottom: 2.4rem;
  span {
    font-weight: 400;
    letter-spacing: 1px;
  }
}

p {
  margin-bottom: 2.4rem;
  text-align: justify;
  font-weight: 400;
}

/* Layout */

header {
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;

  .header__link {
    width: auto;
    display: inline-block;
    position: relative;
    padding: 0;
    text-align: left;
    z-index: 10;
    span {
      display: inline-block;
      object {
        position: relative;
        z-index: -1;
      }
    }
  }
}

.section-perspective {
  -webkit-perspective: 1500px;
  perspective: 1500px;
}

section {
  max-width: 1400px;
  margin: 0 auto;
}

.perspective {
  position: relative;
  overflow: hidden;
  -webkit-perspective: 1500px;
  perspective: 1500px;

  &--modalview {
    -webkit-perspective: 1500px;
    perspective: 1500px;
  }
}

.container {
  margin: 0 auto;
  position: relative;
  -webkit-transform: translateZ(0) translateX(0) rotateY(0deg) rotateX(0.001deg);
  transform: translateZ(0) translateX(0) rotateY(0deg) rotateX(0.001deg);
  height: 100%;
  -webkit-transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow: hidden;
}

.modalview {
  .container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

.effect-rotate-left {
  .container {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    border: 1px solid transparent;
    -webkit-transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, -webkit-box-shadow 0.2s;
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, -webkit-box-shadow 0.2s;
    -webkit-transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, -webkit-box-shadow 0.2s;
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, box-shadow 0.2s;
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, box-shadow 0.2s,
      -webkit-box-shadow 0.2s;
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, box-shadow 0.2s,
      -webkit-box-shadow 0.2s;
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, box-shadow 0.2s,
      -webkit-box-shadow 0.2s;
  }

  &--animate {
    .container {
      padding: 4rem 8rem;
      -webkit-transform: translateZ(-1300px) translateX(-40%) rotateY(45deg);
      transform: translateZ(-1300px) translateX(-40%) rotateY(45deg);
      border: 6px solid $text-color;
      -webkit-box-shadow: 0px 0px 12px $teal-shadow, inset 0px 0px 12px $teal-shadow;
      box-shadow: 0px 0px 12px $teal-shadow, inset 0px 0px 12px $teal-shadow;
      -webkit-transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, -webkit-box-shadow 0.2s;
      transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, -webkit-box-shadow 0.2s;
      -webkit-transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, -webkit-box-shadow 0.2s;
      transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, box-shadow 0.2s;
      transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, box-shadow 0.2s,
        -webkit-box-shadow 0.2s;
      transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, box-shadow 0.2s,
        -webkit-box-shadow 0.2s;
      transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.2s, box-shadow 0.2s,
        -webkit-box-shadow 0.2s;
    }
  }
}

/* CTA */

.cta {
  .link-container {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 1rem;
    border-color: $green;
    -webkit-box-shadow: 0px 0px 6px $green-shadow, inset 0px 0px 6px $green-shadow;
    box-shadow: 0px 0px 6px $green-shadow, inset 0px 0px 6px $green-shadow;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-box-shadow: 0px 0px 32px $green-shadow, inset 0px 0px 18px $green-shadow;
      box-shadow: 0px 0px 32px $green-shadow, inset 0px 0px 18px $green-shadow;
      opacity: 0;
      -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      @include pulsate-fwd-animation;
    }
  }

  a {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-transform: unset;
    letter-spacing: 0px;
    @include light-text;
    border: 0.2rem solid $violet-border;
    -webkit-box-shadow: 0px 0px 6px $violet-shadow, inset 0px 0px 6px $violet-shadow;
    box-shadow: 0px 0px 6px $violet-shadow, inset 0px 0px 6px $violet-shadow;
    //@include pulsate-fwd-invert;

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-box-shadow: 0px 0px 24px $violet-shadow, inset 0px 0px 18px $violet-shadow;
      box-shadow: 0px 0px 24px $violet-shadow, inset 0px 0px 18px $violet-shadow;
      opacity: 0;
      -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      @include pulsate-fwd-animation;
    }
  }

  p {
    margin: 0;
    font-weight: inherit;
    text-align: inherit;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}

/* Animation */

.shutter-in {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: shutter-in 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shutter-in 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.animated {
  opacity: 0;
  -webkit-transform: rotate(0.001deg);
  transform: rotate(0.001deg);
  will-change: transform;
}

@media screen and (min-width: 500px) {
  a,
  button {
    &:hover,
    &:focus {
      outline: none;
      color: $text-color;
      text-shadow: 0px 0px 6px $teal-shadow;
      -webkit-transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }

  .link-container {
    &:hover,
    &:focus {
      -webkit-transform: scale(0.96);
      transform: scale(0.96);
      border: 2px solid $text-color;
      -webkit-box-shadow: 0px 0px 6px $teal-shadow, inset 0px 0px 6px $teal-shadow;
      box-shadow: 0px 0px 6px $teal-shadow, inset 0px 0px 6px $teal-shadow;
      -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);

      a {
        outline: none;
        color: $text-color;
        text-shadow: 0px 0px 4px $teal-shadow;
        -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }
    }
  }

  .cta {
    .link-container {
      &:hover,
      &:focus {
        border-color: $text-color;
        -webkit-box-shadow: 0px 0px 6px $teal-shadow, inset 0px 0px 6px $teal-shadow;
        box-shadow: 0px 0px 6px $teal-shadow, inset 0px 0px 6px $teal-shadow;

        &::after {
          -webkit-box-shadow: 0px 0px 18px $teal-shadow, inset 0px 0px 12px $teal-shadow;
          box-shadow: 0px 0px 18px $teal-shadow, inset 0px 0px 12px $teal-shadow;
        }

        a {
          border-color: $text-color;

          .cta_text,
          .cta_title {
            @include light-text;
          }
        }
      }
    }

    a {
      &:hover,
      &:focus {
        border: 0.2rem solid $text-color;
        -webkit-box-shadow: 0px 0px 12px $teal-shadow, inset 0px 0px 12px $teal-shadow;
        box-shadow: 0px 0px 12px $teal-shadow, inset 0px 0px 12px $teal-shadow;
        .cta_text,
        .cta_title {
          @include light-text;
        }

        &::after {
          -webkit-box-shadow: 0px 0px 24px $teal-shadow, inset 0px 0px 24px $teal-shadow;
          box-shadow: 0px 0px 24px $teal-shadow, inset 0px 0px 24px $teal-shadow;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  h2 {
    font-size: 6.4rem;
  }
}

@media screen and (max-width: 1500px) {
  .container,
  .section-perspective {
    //max-width: 1200px;
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

@media screen and (max-width: 1200px) {
  .large-a {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 768px) {
  .animated {
    opacity: 1;
  }

  body {
    font-size: 2rem;
    background-image: linear-gradient(355deg, $bg-color-2-m 2%, $bg-color-1-m 30%);
    background-color: $bg-color-2-m;
  }

  header {
    padding: 1rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  h2 {
    font-size: 4.8rem;
    font-weight: 300;
  }

  h3 {
    margin-bottom: 1rem;
  }

  h5 {
    font-size: 3.2rem;
  }

  h6 {
    font-size: 1.6rem;
  }

  .h-green {
    font-size: 1.6rem;
    letter-spacing: 2px;
    font-weight: 700;
  }

  a {
    font-size: 1.6rem;
    font-weight: 700;
    padding: 1rem 2.4rem;
    letter-spacing: 2px;
  }

  a,
  button {
    &:active {
      outline: none;
      color: $text-color;
      text-shadow: 0px 0px 6px $teal-shadow;
      -webkit-transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }

  .link-container {
    &:active {
      -webkit-transform: scale(0.96);
      transform: scale(0.96);
      border: 2px solid $text-color;
      -webkit-box-shadow: 0px 0px 6px $teal-shadow, inset 0px 0px 6px $teal-shadow;
      box-shadow: 0px 0px 6px $teal-shadow, inset 0px 0px 6px $teal-shadow;
      -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);

      a {
        outline: none;
        color: $text-color;
        text-shadow: 0px 0px 6px $teal-shadow;
        -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }
    }
  }

  .cta {
    .link-container {
      &:active {
        border-color: $text-color;
        -webkit-box-shadow: 0px 0px 4px $teal-shadow, inset 0px 0px 4px $teal-shadow;
        box-shadow: 0px 0px 4px $teal-shadow, inset 0px 0px 4px $teal-shadow;

        &::after {
          -webkit-box-shadow: 0px 0px 12px $teal-shadow, inset 0px 0px 12px $teal-shadow;
          box-shadow: 0px 0px 12px $teal-shadow, inset 0px 0px 12px $teal-shadow;
        }

        a {
          padding: 1rem;
          border-color: $text-color;

          .cta_text,
          .cta_title {
            @include light-text;
          }
        }
      }
    }

    a {
      &:active {
        border: 0.2rem solid $text-color;
        -webkit-box-shadow: 0px 0px 4px $teal-shadow, inset 0px 0px 4px $teal-shadow;
        box-shadow: 0px 0px 4px $teal-shadow, inset 0px 0px 4px $teal-shadow;
        .cta_text,
        .cta_title {
          @include light-text;
        }

        &::after {
          -webkit-box-shadow: 0px 0px 18px $teal-shadow, inset 0px 0px 18px $teal-shadow;
          box-shadow: 0px 0px 18px $teal-shadow, inset 0px 0px 18px $teal-shadow;
        }
      }
    }
  }

  p {
    text-align: left;
    font-weight: 600;
  }

  .container,
  .section-perspective {
    max-width: 1200px;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .large-a {
    font-size: 3.2rem;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .effect-rotate-left--animate .container {
    padding: 4rem;
    -webkit-transform: translateZ(-1300px) translateY(40%) rotateX(75deg);
    transform: translateZ(-1300px) translateY(40%) rotateX(75deg);
  }
}

@media screen and (max-width: 500px) {
  body {
    font-size: 1.6rem;
  }

  h2 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 375px) {
  .large-a {
    font-size: 2.4rem;
    font-weight: 400;
    padding: 1rem 0;
  }
  .cta {
    a {
      border-width: 0.2rem;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation: none;
    animation: none;
  }
}

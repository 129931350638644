@import '../styles/variables.scss';
.contacts {
  padding: 12rem 0 24rem;

  .cols {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    //align-items: flex-start;
    //flex-wrap: wrap;
    margin: 0 -4rem;

    .col-1 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      @include max-half-width;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin: 8rem 4rem 4rem 4rem;

      .cols-2 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 0 -4rem;
        .col-1,
        .col-2 {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          margin: 0 4rem;
          -webkit-animation-delay: 0.2s;
                  animation-delay: 0.2s;
          div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
          }
          p {
            margin-top: 8rem;
            margin-bottom: 4rem;
          }
        }
      }

      .h-medium {
        max-width: calc((140rem - 8rem) / 2);
      }
    }
    .col-2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      max-width: calc((140rem - 8rem) / 2);
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      margin: 4rem;
    }
  }
}



@media screen and (max-width: 1200px) {
  .contacts {
    .cols {
      .col-1,
      .col-2 {
        width: calc(100% - 8rem);
      }
    }
  }
}

@media screen and (max-height: 800px) {
  .contacts {
    padding: 12rem 0 0 0;
  }
}

@media screen and (max-width: 768px) {
  .contacts {
    padding: 4rem 0 12rem 0;
    .cols {
      .col-1 {
        margin-top: 2rem;
        .cols-2 {
          .col-1 {
            p {
              margin-top: 2rem;
              margin-bottom: 2rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .contacts {
    padding: 4rem 0 4rem 0;
  }
}

.arrow-top {
  overflow: hidden;
  text-align: right;
  button {
    line-height: 1;
    font-family: 'Poppins', sans-serif;
    width: unset;
    font-size: 8rem;
    font-weight: 200;
    padding: 0 3rem;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media screen and (max-width: 768px) {
  .arrow-top {
    overflow: hidden;
    a {
      font-size: 6.4rem;
    }
  }
}

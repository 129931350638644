@import '../styles/_variables.scss';

html,
canvas {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.app-container {
  margin: 0 auto;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  background-color: transparent;
  z-index: -1;
}

.loader {
  margin: 4rem;
  height: 50vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @include scale-in-center;
  .text {
    font-family: 'Source Code Pro', monospace;
    font-size: 2.4rem;
    font-weight: 600;
    letter-spacing: 4px;
    text-shadow: 0px 0px 2px $teal-shadow, 0px 0px 6px $teal-shadow, 0px 0px 12px $teal-shadow,
      0px 0px 24px $teal-shadow;
  }

  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: $text-color;
    margin-left: 1.6rem;
    -webkit-box-shadow: 0px 0px 2px $teal-shadow, 0px 0px 6px $teal-shadow, 0px 0px 12px $teal-shadow,
      0px 0px 24px $teal-shadow;
    box-shadow: 0px 0px 2px $teal-shadow, 0px 0px 6px $teal-shadow, 0px 0px 12px $teal-shadow, 0px 0px 24px $teal-shadow;
    -webkit-animation: blink 2s linear alternate infinite;
    animation: blink 2s linear alternate infinite;

    @for $i from 2 through 4 {
      &:nth-child(#{$i}) {
        -webkit-animation-delay: 0.2s * $i;
        animation-delay: 0.2s * $i;
      }
    }
  }
}

.logo, 
.logo-mark {
  margin: 1rem;
  width: 120px;
  height: 100%;
}

.logo {
  height: 138px;
}



.reload {
  text-align: center;
  padding: 4rem 0;
  max-width: 80%;
  margin: 0 auto;
  @include scale-in-center;
  p {
    text-align: center;
    margin-bottom: 2rem;
  }
  button {
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    max-height: 36px;
  }
}

@import '../styles/variables.scss';


.nav-toggle {
  position: relative;
  margin: 1rem;
  padding: 0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 54px;
  height: 46px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  border: 2px solid $text-color;
  -webkit-box-shadow: 0px 0px 2px $teal-shadow, inset 0px 0px 2px $teal-shadow;
  box-shadow: 0px 0px 2px $teal-shadow, inset 0px 0px 2px $teal-shadow;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0px 0px 18px $teal-shadow, inset 0px 0px 12px $teal-shadow;
    box-shadow: 0px 0px 18px $teal-shadow, inset 0px 0px 12px $teal-shadow;
    opacity: 0;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    @include pulsate-fwd-animation;
  }

  span {
    content: '';
    height: 4px;
    width: 32px;
    background-color: $text-color;
    -webkit-box-shadow: 0px 0px 4px $teal-shadow;
    box-shadow: 0px 0px 4px $teal-shadow;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}

.outer-nav {
  -webkit-perspective: 1500px;
  perspective: 1500px;
  position: absolute;
  top: calc(50% + 100px);
  left: 60%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.2s;
  transition: visibility 0s 0.2s;
  z-index: 100;

  &.is-vis {
    visibility: visible;
  }

  &--return {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    cursor: pointer;
    z-index: 11;

    &.is-vis {
      display: block;
    }
  }

  & > li {
    cursor: pointer;
    text-align: left;
    text-shadow: 0px 0px 6px $teal-shadow;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translateZ(-1000px) translateX(300px) rotate(5deg);
    transform: translateZ(-1000px) translateX(300px) rotate(5deg);
    opacity: 0;
    letter-spacing: 2px;
    -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
    transition: opacity 0.4s, -webkit-transform 0.4s;
    transition: transform 0.4s, opacity 0.4s;
    transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;

    &.is-vis {
      -webkit-transform: translateX(0) translateZ(0) rotate(0);
      transform: translateX(0) translateZ(0) rotate(0);
      opacity: 1;
      -webkit-transition: opacity 0.5s, -webkit-transform 0.6s;
      transition: opacity 0.5s, -webkit-transform 0.6s;
      transition: transform 0.6s, opacity 0.5s;
      transition: transform 0.6s, opacity 0.5s, -webkit-transform 0.6s;
    }

    & > .link-container {
      width: 100%;
    }
  }

  li.is-vis:nth-child(2) {
    -webkit-transition-delay: 0.04s;
    transition-delay: 0.04s;
  }

  li.is-vis:nth-child(3) {
    -webkit-transition-delay: 0.08s;
    transition-delay: 0.08s;
  }

  li.is-vis:nth-child(4) {
    -webkit-transition-delay: 0.12s;
    transition-delay: 0.12s;
  }

  li.is-vis:nth-child(5) {
    -webkit-transition-delay: 0.16s;
    transition-delay: 0.16s;
  }
}

.top-container--small {
  .outer-nav {
    top: calc(50vh + 75px);
  }
}

@media screen and (min-width: 500px) {
  .nav-toggle {
    &:hover,
    &:focus,
    &.active {
      -webkit-transform: scale(0.95);
      transform: scale(0.95);
      border-color: $violet;
      -webkit-box-shadow: 0px 0px 2px $violet-shadow, inset 0px 0px 2px $violet-shadow;
      box-shadow: 0px 0px 2px $violet-shadow, inset 0px 0px 2px $violet-shadow;
      -webkit-animation: none;
      animation: none;

      &::after {
        -webkit-box-shadow: 0px 0px 16px $violet-shadow, inset 0px 0px 12px $violet-shadow;
        box-shadow: 0px 0px 16px $violet-shadow, inset 0px 0px 12px $violet-shadow;
        @include pulsate-fwd-animation;
      }

      span {
        background-color: $violet;
        -webkit-box-shadow: 0px 0px 6px $violet-shadow;
        box-shadow: 0px 0px 6px $violet-shadow;
      }
    }

    &::after {
      &:hover,
      &:focus {
        -webkit-animation: none;
        animation: none;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .outer-nav {
    .link-container {
      margin: 1rem 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .nav-toggle {
    height: 34px;
    width: 42px;
    padding: 0.8rem;
    margin: 1rem 1.5rem;
    span {
      height: 2px;
      width: 24px;
    }

    &.active {
      -webkit-transform: scale(0.95);
      transform: scale(0.95);
      border-color: $violet;
      //box-shadow: 0px 0px 6px $violet-shadow, inset 0px 0px 6px $violet-shadow;
      -webkit-animation: none;
      animation: none;

      &::after {
        -webkit-box-shadow: 0px 0px 16px $violet-shadow, inset 0px 0px 12px $violet-shadow;
        box-shadow: 0px 0px 16px $violet-shadow, inset 0px 0px 12px $violet-shadow;
        @include pulsate-fwd-bck-animation;
      }

      span {
        background-color: $violet;
        -webkit-box-shadow: 0px 0px 6px $violet-shadow;
        box-shadow: 0px 0px 6px $violet-shadow;
      }
    }

    &::after {
      &:active {
        -webkit-animation: none;
        animation: none;
        //@include pulsate-fwd-animation;
      }
    }
  }

  .outer-nav {
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .outer-nav > li > .link-container {
    margin: 0.8rem 0;
  }

  .top-container--small {
    .outer-nav {
      top: calc(35vh);
    }
  }
}

@media screen and (max-width: 375px) {
  .outer-nav {
    a {
      padding: 1rem;
    }
  }
}

@media screen and (max-height: 600px) {
  .outer-nav {
    li .link-container {
      margin: 0.5rem 0;
      a {
        padding: 0.8rem;
      }
    }
  }
}

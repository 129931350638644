@import '../styles/variables.scss';

.about {
  padding: 16rem 0 0 0;
  -webkit-perspective: 1500px;
  perspective: 1500px;
  .cols {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 1500px;
    perspective: 1500px;
    width: 100%;
  }

  .col-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    margin: 8rem 0;

    .story {
      @include half-width;
    }
    p {
      margin-top: 8rem;
    }
  }

  .col-2 {
    margin: 16rem 0 0 0;

    h5 {
      font-weight: 800;
      text-transform: uppercase;
      font-size: 4rem;
      letter-spacing: 2.4rem;
    }

    .items {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      //flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 6rem -4rem 0 -4rem;

      .item {
        margin: 4rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
        &:nth-child(2) {
          -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
        }
        &:nth-child(3) {
          -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
        }
        p {
          font-size: 2rem;
          line-height: 1.6;
          font-weight: 600;
        }
      }
    }
  }

  .cta {
    width: 100%;
    text-align: right;
    margin: 8rem 0;

    .link-container {
      max-width: calc((140rem - 4rem) / 3 * 2);

      a {
        text-transform: unset;
        padding: 2rem 6rem;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;

        .cta_title {
          font-family: 'Source Code Pro', monospace;
          font-size: 2rem;
          font-weight: 600;
          line-height: 1.6;
          margin: 2rem;
        }

        .cta_text {
          text-transform: uppercase;
          font-size: 4rem;
          font-weight: 800;
          letter-spacing: 4px;
          margin: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .about {
    .col-1 {
      .story {
        width: 100%;
      }
    }
    .col-2 {
      .items {
        .item {
          p {
            text-align: left;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .about {
    .cols {
      .col-2 {
        .items {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          .item {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about {
    padding: 4rem 0 0 0;
    .cols {
      .col-1 {
        margin: 2rem;
        p {
          margin-top: 4rem;
        }
      }
      .col-2 {
        margin: 8rem 0 0 0;
        h5 {
          letter-spacing: 8px;
        }
        .items {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          margin-top: 4rem;
          .item {
            margin: 2rem 4rem 4rem 4rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .about {
    .col-2 {
      .items {
        .item {
          p {
            font-size: 1.6rem;
          }
        }
      }
      h5 {
        font-size: 2.4rem;
      }
    }
  }
}

@import '../styles/variables';

.services {
  padding: 16rem 0 8rem 0;
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;

  .cols {
    margin: 6rem 0;
    -webkit-perspective: 1500px;
    perspective: 1500px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  ul {
    margin: 2rem 0;
  }

  .items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-perspective: 1500px;
    perspective: 1500px;
    margin: 0 -4rem;

    .item {
      @include third-width;
      margin: 4rem;

      &:not(:last-child)::before {
        content: '';
        margin-bottom: 0.8rem;
        display: inline-block;
        height: 1.6rem;
        width: 100%;
        background-color: $text-color;
        -webkit-box-shadow: 0px 0px 6px $teal-shadow;
        box-shadow: 0px 0px 6px $teal-shadow;
      }

      &:nth-child(1) {
        -webkit-animation-delay: 0.1s;
                animation-delay: 0.1s;
      }
      &:nth-child(2) {
        -webkit-animation-delay: 0.2s;
                animation-delay: 0.2s;
      }
      &:nth-child(3) {
        -webkit-animation-delay: 0.3s;
                animation-delay: 0.3s;
      }

      &:nth-child(4) {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        @include two-thirds;
        -webkit-animation-delay: 0.3s;
                animation-delay: 0.3s;
      }

      &:nth-child(5) {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-animation-delay: 0.5s;
                animation-delay: 0.5s;
      }
    }
  }

  .cta {
    width: 100%;

    .link-container {
      a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        .cta_text {
          font-family: 'Source Code Pro', monospace;
          font-size: 2rem;
          font-weight: 600;
          line-height: 1.6;
          margin: 2rem;
        }
        .cta_title {
          text-transform: unset;
          font-size: 4.8rem;
          font-weight: 700;
          letter-spacing: 1px;
          line-height: 1;
          margin: 2rem 0;
        }
      }
    }
  }

  li {
    line-height: 2.4;
  }
}

@media screen and (max-width: 1200px) {
  .services {
    .items {
      .item {
        @include half-width;
        &:nth-child(4) {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .services {
    padding: 4rem 0 0 0;
    .cols {
      margin: 0;
      .items {
        .item {
          font-size: 2rem;
          font-weight: 600;
          margin: 1rem 4rem;

          &:not(:last-child)::before {
            margin-bottom: 0;
            height: 0px;
          }

          &:nth-child(5) {
            margin: 8rem 4rem;
          }
        }
      }
    }
    p,
    ul {
      font-size: 1.6rem;
    }
    .cta {
      .link-container {
        a {
          .cta_title {
            font-size: 3.2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .services {
    .items {
      .item {
        width: 100%;
        &:nth-child(4) {
          -webkit-box-ordinal-group: 5;
          -ms-flex-order: 4;
          order: 4;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .services .cta .link-container a {
    padding: 1rem;
    .cta_text {
      margin: 1rem 0;
    }
  }
}
